import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Slider from "react-slick"
import Project from "../components/Sidebar/Project"
import PostForm from "../components/Form/PostForm"
// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
//  import "../css/@wordpress/block-library/build-style/theme.css"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    image: post.featuredImage?.node?.localFile,
    alt: post.featuredImage?.node?.alt || ``,
  }
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    fade: false,
    arrows: true,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo
        title={post.title}
        description={`${post.title} - Sàn bất động sản uy tín, chuyên nghiệp Alphaland.`}
        keywords={`${post.title} - Sàn bất động sản, nhà đất tp hcm, dự án bất động sản, chung cư tphcm, nhà đất Bình Dương, Nhà đất Đồng Nai`}
        seoImage={
          "https://www.alphaholdings.vn" +
          (featuredImage
            ? featuredImage.image.childImageSharp.gatsbyImageData.images
                .fallback.src
            : "/ogimage-default.jpg")
        }
      />
      <div className="big-title mb-5">
        <div className="container text-center">
          <h1>{post.title}</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-xs-12">
            <div className="detail">
              {(() => {
                if (post.CustomPost.address != null) {
                  return (
                    <>
                      <div className="overview pb-3">
                        <Slider {...settings} className="pb-3 mb-3">
                          {post.CustomPost.gallery &&
                            post.CustomPost.gallery.map((prop, index) => (
                              <div key={index}>
                                <GatsbyImage
                                  image={getImage(prop.localFile)}
                                  alt={prop.altText ?? "khong co hinh"}
                                />
                              </div>
                            ))}
                        </Slider>
                        <h3 className="detail-title">Thông tin chính</h3>
                        <ul>
                          <li>
                            <strong>{post.title}</strong>
                          </li>
                          {(() => {
                            if (post.CustomPost.investor != null) {
                              return (
                                <li>
                                  Chủ đầu tư:{" "}
                                  <strong>{post.CustomPost.investor}</strong>
                                </li>
                              )
                            }
                          })()}
                          <li>
                            Địa chỉ: <strong>{post.CustomPost.address}</strong>
                          </li>
                          <li className="price">
                            Giá: từ <strong>{post.CustomPost.price}</strong>
                          </li>
                        </ul>
                      </div>
                      <a
                        href="#form-wrapper"
                        type="button"
                        className="btn btn-warning"
                      >
                        Liên hệ
                      </a>
                    </>
                  )
                }
              })()}

              <div className="info pb-3">
                {(() => {
                  if (post.CustomPost.address === null) {
                    return (
                      <>
                        <div>{parse(post.content)}</div>
                      </>
                    )
                  } else {
                    return (
                      <>
                        <h3 className="detail-title">Giới thiệu</h3>
                        <div>{parse(post.content)}</div>
                      </>
                    )
                  }
                })()}
              </div>

              {(() => {
                if (post.CustomPost.location != null) {
                  return (
                    <div className="localtion pb-3 mb-2">
                      <h3 className="detail-title">Vị trí</h3>
                      <div> {parse(post.CustomPost.location)}</div>
                    </div>
                  )
                }
              })()}

              {(() => {
                if (post.CustomPost.utility != null) {
                  return (
                    <div className="utilities pb-3">
                      <h3 className="detail-title">Tiện ích xung quanh</h3>
                      <div className="row">
                        {post.CustomPost.utility &&
                          post.CustomPost.utility.map((prop, index) => (
                            <div className="col-md-4 col-xs-12" key={index}>
                              <p>
                                <i
                                  className="fa fa-hand-o-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                {prop.title}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                }
              })()}
            </div>
            <div className="news_papers">
              {(() => {
                if (post.news_papers.newsPapers !== null) {
                  return (
                    <h3 className="detail-title pt-3">Báo chí nói về dự án</h3>
                  )
                }
              })()}
              <div className="row">
                {post.news_papers.newsPapers &&
                  post.news_papers.newsPapers.map(
                    (prop, index) =>
                      prop.title && (
                        <div className="col-md-4 col-xs-12" key={index}>
                          <div className="image">
                            <a
                              href={prop.link}
                              title={prop.title}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {prop.image && (
                                <GatsbyImage
                                  image={getImage(prop.image.localFile)}
                                  alt="hinh bai viet"
                                />
                              )}
                            </a>
                          </div>
                          <div className="content">
                            <h3 className="title">
                              <a
                                href={prop.link}
                                title={prop.title}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {prop.title}
                              </a>
                            </h3>
                            <div className="excerpt">
                              <div key={`body`} id="___gatsby">
                                {parse(prop.excerpt)}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            <aside>
              {(() => {
                if (post.CustomPost.address != null) {
                  return (
                    <>
                      <div className="contact-info text-center">
                        <div className="title-sidebar">
                          <h3>Hotline hỗ trợ khách hàng</h3>
                        </div>
                        {(() => {
                          if (post.CustomPost.phone != null) {
                            return (
                              <h2>
                                <a href={`tel:${post.CustomPost.phone}`}>
                                  {post.CustomPost.phone}
                                </a>
                              </h2>
                            )
                          } else {
                            return (
                              <h2>
                                <a href={`tel:0911234999`}> 0911 234 999</a>
                              </h2>
                            )
                          }
                        })()}
                      </div>
                      <PostForm pageContext={{ post, title: post.title }} />
                    </>
                  )
                }
              })()}
              <Project />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      title
      content
      link
      featuredImage {
        node {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                quality: 90
                width: 800
                aspectRatio: 1.5
              )
            }
          }
        }
      }
      CustomPost {
        address
        price
        bedroom
        wc
        acreage
        location
        phone
        investor
        utility {
          title
        }
        gallery {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                quality: 90
                width: 800
                aspectRatio: 1.5
              )
            }
          }
        }
      }
      news_papers {
        fieldGroupName
        newsPapers {
          excerpt
          fieldGroupName
          link
          title
          image {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  quality: 90
                  width: 800
                  aspectRatio: 1.5
                )
              }
            }
          }
        }
      }
      tags {
        nodes {
          id
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
          id
        }
      }
      author {
        node {
          slug
          name
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
