import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { blogURI } from "../../../globals"

const Project = () => {
  return (
    <div className="project-category">
      {/* <div className="container"> */}
      <div className="title-sidebar">
        <h3>Dự án mới</h3>
      </div>
      <ul>
        <StaticQuery
          query={POST_QUERY}
          render={data => {
            if (data.allWpPost.projects) {
              const posts = data.allWpPost.projects

              return (
                <>
                  {posts &&
                    posts.map((prop, index) => (
                      <li className="item" key={index}>
                        <div className="featured-image">
                          <Link to={`${prop.uri}`}>
                            <div className="ov1"></div>
                            <div className="ov2"></div>
                            <div className="ov3"></div>
                            <div className="ov4"></div>
                            {prop.featuredImage.node && (
                              <GatsbyImage
                                image={getImage(
                                  prop.featuredImage.node.localFile
                                )}
                                alt={prop.featuredImage.node.altText ?? 'khong co hinh'}
                              />
                            )}
                          </Link>
                        </div>
                        <div className="title">
                          <Link to={`${prop.uri}`} title={prop.title}>
                            <h3>{prop.title}</h3>
                          </Link>
                        </div>
                        <div className="excerpt">
                          <div className="address">
                            <span>
                              <i
                                className="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>
                              {prop.CustomPost.address}
                            </span>
                          </div>
                          <div className="price">
                            <span>Giá: ~ {prop.CustomPost.price}</span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>{prop.CustomPost.bedroom}</div>
                            <div>{prop.CustomPost.wc}</div>
                            <div>{prop.CustomPost.acreage}</div>
                          </div>
                        </div>
                      </li>
                    ))}
                </>
              )
            }
            return null
          }}
        />
      </ul>
      {/* </div> */}
    </div>
  )
}

export default Project

const POST_QUERY = graphql`
  query GETPROJECT {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "du-an" } } } }
      }
      sort: { fields: date, order: DESC }
      limit: 6
    ) {
      projects: nodes {
        title
        uri
        excerpt
        featuredImage {
          node {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000, height: 600, quality: 80, placeholder: TRACED_SVG)
              }
            }
          }
        }
        CustomPost {
          acreage
          address
          bedroom
          fieldGroupName
          price
        }
      }
    }
  }
`
