import axios from "axios";
import React from "react";
// import Recaptcha from "react-recaptcha";
// import { siteKey } from "../../../globals";
class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: "",
      name: "",
      email: "",
      notes: "",
      g_recaptcha_response: "",
      mailSent: false,
      error: null,
      disableSubmit: false
    }
    this.txtPhone = React.createRef();
  }
  // recaptchaInstance = null
  handleFormSubmit = e => {
    e.preventDefault()
    if(this.state.disableSubmit){
      this.setState({ error: 'Đang gửi yêu cầu, xin vui lòng chờ giây lát' })
      return 
    }
    // if (this.state.g_recaptcha_response.trim() === "") {
    //   this.setState({ error: 'Xin vui lòng nhập captcha trước khi gửi thông tin' })
    //   this.setState({ disableSubmit: false })
    // } else
    if (this.state.phone.trim() === "" || this.state.name.trim() === "") {
      this.setState({ error: 'Xin vui lòng tên và số điện thoại' })
      this.txtPhone.current.select();
      this.setState({ disableSubmit: false })
    } else if (!(/^\d+$/.test(this.state.phone)) || this.state.phone.length < 10) {
      this.setState({ error: 'Xin vui lòng nhập đúng số điện thoại' })
      this.txtPhone.current.focus();
      this.txtPhone.current.select();
      this.setState({ disableSubmit: false })
    } else {
      this.setState({ disableSubmit: true })
      var bodyFormData = new FormData()
      bodyFormData.append("formid", "1")
      bodyFormData.append("url", window.location.href)
      bodyFormData.append("title", this.props.pageContext.title)
      bodyFormData.append("name", this.state.name)
      bodyFormData.append("phone", this.state.phone)
      bodyFormData.append("email", this.state.email)
      bodyFormData.append("notes", this.state.notes)
      // bodyFormData.append("g-recaptcha-response", this.state.g_recaptcha_response)
      axios({
        method: "post",
        url: process.env.CONTACT_FORM_URL || "https://alphaholdings-form.000webhostapp.com/form_submit1.php",
        headers: { "Content-Type": "application/json" },
        data: bodyFormData,
      })
        .then(response => {
          if (response.data.status === '400' || response.data.status === '500') {
            this.setState({ error: response.data.message, disableSubmit: false })
          } else {
            this.setState({ mailSent: response.data.message })
            if (response.data && response.data.status == '200') {
              window.location.href = "/thanks"
            }
          }
        })
        .catch(error => {
          this.setState({ error: "Đã có lỗi xảy ra", disableSubmit: false })
        })
    }
  }

  //handle changing fields

  handleChange = (e, field) => {
    let value = e.target.value
    let updateValue = {}
    updateValue[field] = value
    this.setState(updateValue)
  }

  callback = () => {
  }

  verifyCallback = response => {
    this.setState({ g_recaptcha_response: response });
  }

  render() {
    const { post } = this.props.pageContext
    const note = "Xin chào Alphaland! Tôi đang quan tâm dến sản phẩm " + post.title

    return (
      <>
        <div className="form-wrapper" id="form-wrapper">
          <h3>Liên hệ tư vấn</h3>
          <form action="#">
            <div className="form-group">
              <input
                type="hidden"
                className="form-control"
                value="1"
                onChange={e => this.handleChange(e, "formid")}
              />
            </div>
            <div className="form-group">
              <input
                type="hidden"
                className="form-control"
                value={this.state.url}
                onChange={e => this.handleChange(e, "url")}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Họ và tên *"
                value={this.state.name}
                onChange={e => this.handleChange(e, "name")}
                required
              />
            </div>
            <div className="form-group">
              <input
                ref={this.txtPhone}
                className="form-control"
                type="text"
                placeholder="Số điện thoại *"
                value={this.state.phone}
                onChange={e => this.handleChange(e, "phone")}
                required
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Địa chỉ email"
                value={this.state.email}
                onChange={e => this.handleChange(e, "email")}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={post.title}
                disabled
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder={note}
                value={this.state.notes}
                onChange={e => this.handleChange(e, "notes")}
                rows="5"
              />
            </div>
            {/* <Recaptcha
              ref={e => this.recaptchaInstance = e}
              sitekey={siteKey}
              render="explicit"
              verifyCallback={this.verifyCallback}
              onloadCallback={this.callback}
            /> */}
            <input
              type="submit"
              disabled={this.state.disableSubmit}
              onClick={e => this.handleFormSubmit(e)}
              value="Gửi"
            />

            <div>
              {/* <div className="alert alert-success">{successMail}</div>
            <div className="alert alert-danger">{mailEmty}</div>
            <div className="alert alert-danger">{errorMail}</div> */}
              {this.state.mailSent && (
                <div className="alert alert-success">
                  {this.state.mailSent}
                </div>
              )}
              {this.state.error && (
                <div className="alert alert-danger">
                  {this.state.error}
                </div>
              )}
            </div>
          </form>
        </div>
      </>
    )
  }
}

export default Form
